body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-xl {
  max-width: 1600px;
  padding-right: 0px;
  padding-left: 0px; 
}

.card {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
  margin-top: '1rem';
   margin-bottom: '1rem';
}

.card-header {
  background-color: #fff;
}

.card-footer {
  background-color: #fff;
}

a:not([href]) {
  color: #fff;
  text-decoration: none;
}

.star {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: 2px;
  float: left;
  background-color: grey;
  clip-path: none;
 -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
}
.star.selected {
  background-color: #0A5699;
}

.btn-sm {
  border-radius: 1rem;
}

.btn {
  border-radius: 1rem;
}